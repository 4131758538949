import React from 'react'
import { Link } from "gatsby"
import Footer from "../components/footer"
import SEO from "../components/seo"

import Termsheader from '../components/termsheader'
const Terms = () => {
    return (
        <div>
          <SEO title="Terms" />
          <Termsheader/> 
          <div 
            style={{
                paddingTop: "50px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: "150%",
                fontFamily: "font-family: pro;",
              }}
            >
            <div 
              style={{
                width: "84%",
                padding: "9px",
                }}>
           <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
              INTRODUCTION                    
            </div>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
              1.  These general Terms and Conditions (Terms and Conditions” or
            “T&C”) shall be valid for all gaming services offered by TETE an
            application with registered trademark under Tete Technologies Ltd.
            (a company duly organized under the laws of Nigeria, with business
            registration RC 1675344.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            2. TETE shall offer gaming services through its application software
            hereinafter referred to as “the app”.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            3. By using the app you are bound by the following terms and
            conditions:
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            <p style={{fontSize: "17px", paddingLeft: "40px"}}> ● The General Terms and conditions</p>
            <p style={{fontSize: "17px", paddingLeft: "40px"}}>● The privacy policy;</p>

            <p style={{fontSize: "17px", paddingLeft: "40px"}}>
              ● Any terms and condition and/or rules concerning bonuses,
              promotions and special offers which may be advertised in any part
              of our website;
            </p>
            <p style={{fontSize: "17px", paddingLeft: "40px"}}>
              ● Any further terms and conditions contained in any of the
              software which you may download in order to be able to use the
              service we provide.
            </p>
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            4. All the terms and condition listed in this page shall together be
            referred to as “the Terms of Use”. “User” and “Customer”, “You” and
            “Your” refers to you, the person assessing our app and accepting the
            Company’s terms and conditions. Whenever the Customer uses our app
            such as to participate in any gaming services offered, he agrees to
            be bound by the Terms of Use, including any amendments which may
            periodically take place.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            5. TETE is devoted to ensuring that the app is as reliable and
            efficient as possible. Therefore, TETE reserves the right to make
            changes to the app or to charge for its services, at any time and in
            circumstance. Users will be clearly notified prior to this if
            charges will be made.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            6. The Terms and condition contained herein represent the complete,
            final and exclusive agreement between the Customer and the company
            and supersede and merge all prior agreements, representations and
            understandings. These terms will automatically apply to users of
            this app therefore, you should ensure that you read them thoroughly
            before using the app
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            7. The gaming services that we offer are strictly prohibited for
            persons less than 18 years of age or under the age of legal consent
            for using online betting and gaming services under the laws of the
            jurisdiction in which the person is located. Consequently, minors
            cannot register or play on the app. Any minor who is found to be
            using the app shall have his/her account immediately blocked. TETE
            reserves the right to conduct a security review to validate your
            identity and age. Registration of the Account authorises us to
            conduct such security reviews supplied by you against third party
            databases.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            8. Non-Commercial, Non-Professional purposes: the customer’s
            interest in using the app is of a personal nature, and is to be used
            purely for personal entertainment. Any commercial and professional
            interest is discarded.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            9. With respect to TETE’s responsibility for your use of the app,
            when you’re using the app, it’s important to bear in mind that as
            much as we endeavour to ensure that it is updated and correct at all
            times, we do rely on third parties to provide information to us so
            that we can make it available to you. TETE accepts no liability for
            any loss, direct or indirect, you experience as a result of relying
            wholly on this functionality of the app.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            10. TETE will not be liable for certain things not under our
            control, it is necessary you read them through and understand them
            clearly. Most functions in the app will need the app to have an
            active internet connection. The connection can be Wi-Fi, or provided
            by your mobile network provider, but TETE cannot take responsibility
            for the app not working optimally if your internet connection is
            poor. We advise you bar calls from coming in during game play when
            on mobile network, so as to not affect internet data
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            11. When using your mobile data, the terms and condition of the
            agreement with your mobile network provider will still apply .
            Therefore, your mobile network provider may charge you for the cost
            of the data used during the use of TETE app or other third party
            apps. In using the app, you are accepting responsibility for any
            such charges, including roaming data charges when you use your
            country mobile data outside the country. In case you are not
            responsible for paying the data bill in the device accessing the
            app, we automatically believe that you have received authorization
            from the bill payer..
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            12. In the same way, TETE cannot always take responsibility for the
            way you use the app, meaning you need to ensure that your device
            stays charged, if it runs out of battery and you get disconnected
            before reconnecting on the app, TETE cannot accept responsibility.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            13. With respect to TETE’s responsibility for your use of the app,
            when you’re using the app, it’s important to bear in mind that as
            much as we endeavour to ensure that it is updated and correct at all
            times, we do rely on third parties to provide information to us so
            that we can make it available to you. TETE accepts no liability for
            any loss, direct or indirect, you experience as a result of relying
            wholly on this functionality of the app.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            14. In trying to make the app better, we may want to update the app.
            The app is currently available on – the requirements for the system
            may change, and you will need to a the updates if you want to keep
            using the app. TETE does not promise that it will always update the
            app so that it is relevant to you and/or works with the version that
            you have installed on your device. However, you promise to always
            accept updates to the application when offered to you, We may also
            wish to stop providing the app, and may terminate use of it at any
            time without giving notice of termination to you. Unless we tell you
            otherwise, upon any termination, the rights and licenses granted to
            you in these terms will end and you must stop using the app, and
            might have to delete it from your device.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            15. TETE is devoted to ensuring that the app is as reliable and
            efficient as possible. Therefore, TETE reserves the right to make
            changes to the app or to charge for its services, at any time and
            circumstance. Users will be clearly notified prior to this if
            charges will be made.
          </p>
          <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
            RESTRICTION OF USE                    
          </div>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            1. Under no circumstance shall the app be used for any purpose
            different from personal entertainment without our express consent.
            TETE is not liable for any attempts to use the services offered by
            means or ways not intended by us.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            2. Copying, modifying the app or any part of the app is highly
            prohibited including the trademarks. It is prohibited to attempt to
            extract the source code of the app, or attempt to translate the app
            into other languages, or deriving versions from it. TETE completely
            owns all trademarks, domains, logo, images, copyright, database
            rights, intellectual property related to it and our App itself. All
            necessary legal actions will be undertaken in order to ensure
            compliance with this policy
          </p>
          <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
            PERSONAL DATA                    
          </div>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            1. The TETE app stores and processes personal data that you have
            provided to us, in order to provide our Service. Users are solely
            responsible to safeguard your devices and access to the app secure.
            Hence, we recommend that you do not jailbreak or root your phone,
            which is the process of removing software restrictions and
            limitations imposed by the official operating system of your device.
            This can make your phone unprotected from malware/viruses/malicious
            programs, compromise your phone’s security features and can cause
            your TETE app malfunction.
          </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            2. We keep your Personal Data to enable your continued use of the
            Service, for as long as it is required in order to fulfil the
            relevant purposes, as may be required by law such as for anti-money
            laundering and counter terrorist financing, tax and accounting
            purposes, or as otherwise communicated to you. This applies to us
            retaining your data after you close the Account.
          </p>
          <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
            RULE OF CONDUCT                    
          </div>        
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            1. You are solely responsible for your use of Services and Software,
            and while using Services and Software you must conduct yourself in a
            lawful and respectful manner in accordance with our rules of conduct
            below. We may temporarily or permanently ban users who violate these
            rules, or who abuse email communications, support communications, or
            the community purpose of any message board areas, as determined by
            TETE and third parties associated with us. At TETE’s discretion we
            reserve the right to disable a player’s ability to upload profile
            photos or edit their username at any time or use the chat feature.
          </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              a) Curse words, or the use of *asterisks* or other “masking”
              characters to disguise such words, is not permitted.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              b) You may not use or upload obscene, slanderous, pornographic,
              abusive, violent, insulting, indecent, threatening and harassing
              language of any kind, as determined by us in our sole discretion.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              c) Service usernames will be displayed as ‘Your Name’;
              impersonating other players is not allowed.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              d) Do not share personal information such as but not limited to
              your name, phone number, home address, and password with other
              users.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              e) Do not transmit or upload any copyrighted or trademarked
              materials in messages or postings.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              f) Information disclosed in chat rooms or gameplay dialogue is
              revealed to the public, and neither we, nor our partners, is
              responsible for information you choose to disclose to others.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              g) Advanced fonts, java, tables, html or other programming codes
              or commands are not allowed in messages.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              h) You may not attempt to participate in any Service by means of
              automatic, macro, programmed or similar methods.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              i) You may not commit fraud with regard to any Service.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              j) You may not attempt to impersonate or deceive another user for
              the purposes of illicitly obtaining cards, passwords, and account
              information.{" "}
            </p>

          <p style={{fontSize: "17px", opacity: "0.6"}}>
            Users can report other abusive users on the app, this will be
            investigated by TETE in less than 24 hours after the report and
            users found guilty can be temporarily or permanently banned from
            using the app.{" "}
          </p>

           <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
            CUSTOMER SERVICE                    
           </div>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              1. If you wish to make a complaint or a suggestion regarding the
              app, the website or the service offered, as a first step you
              should contact our customer service or send us an email through
              our website tete.ng or tete.ng.com. As second step, the complaint
              shall be assigned to an advisor that will manage to provide you
              with the appropriate response.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              2. All complaint and suggestions should be forwarded to the
              Customer Service in written form via internal message and email.
              No complaint will be heard by us if they are not raised through
              our customer service within 30 days from the occurrence of the
              event.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              3. Offensive or rude language, as well as malicious or damaging
              comments will not be tolerated while contacting our staff or while
              discussing our product and services in any media, social network
              or forum. Any infringement of this policy will result in
              suspension of the account or every additional action that may be
              required to ensure compliance.{" "}
            </p>
            <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
            DISPUTE RESOLUTION/ LAWS                    
            </div>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              1. In no event will any delay, failure or omission (in whole or
              part) in enforcing, exercising or pursuing any right, power,
              privilege, claim, or remedy conferred by or arising under these
              Terms and Conditions, be deemed to be or construed as a waiver of
              that or any other right, power privilege, claim or remedy in
              respect of the circumstance in question, or operate so as to bar
              the enforcement of that, or any other right, power, privilege,
              claim or remedy, in any other instance at any time or
              subsequently.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              2. If any provision of these Terms and conditions is found by any
              court or administrative body of competent jurisdiction to be in
              valid or unenforceable, such invalidity or unenforceability shall
              not affect the other provisions of these Terms and Conditions
              which shall remain in full force and effect.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              3. In such instances, the part declared invalid or unenforceable
              shall be amended in a manner consistent with the applicable law to
              reflect, as closely as possible TETE’s original intent.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              4. The app activities shall be governed by applicable provisions
              of the Laws of the Federal Republic of Nigeria and any rules and
              regulations made in terms thereof, as amended from time to time.
              It shall be the responsibility of the customer to ensure that
              he/she is aware of these provisions.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              5. The gaming contract, as well as any other legal relationship
              between the Customer and the Company, for every circumstances not
              regulated by the Terms of Use, is subject to Nigerian Laws.
            </p>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              6. If the customer makes use of the app services from a location
              falling outside Nigeria, such services shall be subject to all
              appropriate exchange control regulations and the laws of the
              foreign jurisdiction from which such communication originates and
              it shall be the responsibility of the Customer to ensure full
              compliance with same. TETE makes no warranties and shall not be
              liable to the customer if it is not able to remit any monies held
              by it to any account held by the customer in a foreign
              jurisdiction.
            </p>
           <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
            Changes to Terms and Conditions                    
          </div>
            <p style={{fontSize: "17px", opacity: "0.6"}}>
              We may update our Terms and Conditions from time to time. Thus,
              you are advised to review this page periodically for any changes.
              We will notify you of any changes by posting the new Terms and
              Conditions on this page. These changes are effective immediately
              after they are posted on this page.
            </p>
          <p style={{fontSize: "17px", opacity: "0.6"}}>
            These General Terms and Conditions are effective from 25th day of
            July, 2020.
          </p>
          </div>
        </div>
        <Footer/>  
        </div>
    )
}

export default Terms